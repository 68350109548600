import 'core-js/es/array/iterator';
import 'lazysizes';

// Import our CSS
import 'prismjs/themes/prism.css'
import styles from '../scss/portal.scss';

//Import vue components
import { VueAgile } from 'vue-agile'
import Prism from 'vue-prismjs'
import ResponsiveBrowser from './components/ResponsiveBrowser.vue'
import CookiePopup from './components/CookiePopup.vue'
import { Youtube } from 'vue-youtube'
import Vuex from 'vuex'
import AOS from 'aos';
// import VueEqualHeights from './plugins/EqualHeights'

// App main
const main = async () => {
	// Async load the vue module
	const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

	//Enable Equal Heights plugin
	// Vue.use(VueEqualHeights)

	//Enable Vuex store
	Vue.use(Vuex)

	const store = new Vuex.Store({
		state: {
			isMobileMenuOpen: false,
			scrollPosition: 0
		},
		mutations: {
			closeMobileMenu(state) {
				state.isMobileMenuOpen = false
			},
			openMobileMenu(state) {
				state.isMobileMenuOpen = true
			},
			adjustScrollPosition(state, position) {
				state.scrollPosition = position
			}
		}
	});

	// Create our vue instance
	const vm = new Vue({
		el: "#app",
		delimiters: ['${', '}'],
		store,
		components: {
			agile: VueAgile,
			Prism,
			ResponsiveBrowser,
			Youtube,
			CookiePopup
		},
		data: {
		},
		methods: {
			toggleMobileMenu: function (event) {

				switch (store.state.isMobileMenuOpen) {
					case true:
						store.commit('closeMobileMenu')
						break

					case false:
						store.commit('openMobileMenu')
						break

					default:
						store.commit('closeMobileMenu')
						break
				}

			},
		},
		computed: {
			isMobileMenuOpen() {
				return store.state.isMobileMenuOpen
			}
		},
		created() {
		},
		destroyed() {
		},
		mounted() {

		},
	});

	return vm;
};

// Execute async function
main().then((vm) => {

});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept();
}
